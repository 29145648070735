<template></template>

<script setup>

    const router = useRouter()
    const { locale, locales, setLocale } = useI18n()

    // // default props available on error.vue
    // const props = defineProps({
    //     error: Object,
    // });

    // const storedTheme = localStorage.getItem('theme');
    // const selectedTheme = ref(storedTheme);

    // onMounted(() => {
    //     if(selectedTheme.value === 'auto') {
    //         document.documentElement.setAttribute('data-bs-theme', 'dark');
    //     } else {
    //         document.documentElement.setAttribute('data-bs-theme', selectedTheme.value);
    //     }
    // })

    // customise 404 message from script section
    const error = useError();

    if(error.value.data.path == '/') {

        clearError({ redirect: `/${locale.value}` });
    }

    else if (error.value.statusCode === 404 || '404') {
        // error.value.message = '[script]: Oops! Page not found 😔';
        //router.push(`/${locale.value}/page-not-found`);
        clearError({ redirect: '/en/page-not-found' });
    }

    // clear error and redirect to home page
    const handleError = () => clearError({ redirect: `/${locale.value}/home` });

</script>
